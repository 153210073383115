import "./styles.css";
import DisplayResults from "@3d-dice/fui/src/displayResults"; // fui index exports are messed up -> going to src
import DiceParser from "@3d-dice/fdp";
import { Dice } from "./components/diceBox";
import ObsWebSocket from "obs-websocket-js";

// create Dice Roll Parser to handle complex notations
const DRP = new DiceParser();

// create display overlay for final results
const DiceResults = new DisplayResults("#dice-box");

const queryParams = new URLSearchParams(window.location.search);
let obsAdress = queryParams.get("obsaddress");
let obsPort = queryParams.get("obsport");
const obsPassword = queryParams.get("obspassword");

if(obsAdress === null){
  obsAdress = "localhost";
}

if(obsPort === null){
  obsPort = "4444";
}

const obs = new ObsWebSocket();
if (obsPassword !== null) {
  obs.connect({address: obsAdress + ":" + obsPort, password: obsPassword});
} else {
  obs.connect({address: obsAdress + ":" + obsPort});
}

// initialize the Dice Box outside of the component
Dice.init().then(() => {
  obs.on('BroadcastCustomMessage', (event) => {
    if(event.realm === '3ddiceroller'){
      Dice.onRollComplete = (results) => {
        const rerolls = DRP.handleRerolls(results);
        if (rerolls.length) {
          rerolls.forEach((roll) => Dice.add(roll, roll.groupId));
          return rerolls;
        }
        // if no rerolls needed then parse the final results
        const finalResults = DRP.parseFinalResults(results);
        if(event.data.showResultsOnScreen === true){
          DiceResults.showResults(finalResults);
        }
        setTimeout(() => {
          obs.send('BroadcastCustomMessage', {
            realm: '3ddicerollresult', data: {
              uuid: event.data.uuid,
              payload: event.data.payload,
              error: null,
              diceResult: finalResults
            }
          });
          DiceResults.clear();
          Dice.hide();
          Dice.clear();
        }, 1500);
      }
      let notation;
      try {
        notation = DRP.parseNotation(event.data.notation);
      } catch (error) {
        obs.send('BroadcastCustomMessage', {
          realm: '3ddicerollresult', data: {
            uuid: event.data.uuid,
            payload: event.data.payload,
            error: error.message,
            diceResult: null
          }
        });
        return;
      }
      Dice.show().roll(notation, {theme: event.data.theme});
    }
  });
});

export default async function App() {

}
